.modal-container {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    right: 10px;
    z-index: 1000;
    background-color: white;
    border-radius: 20px;
}

.file-content {
    border-bottom: 1px solid rgb(211, 210, 210);
}

.custom-modal {
    display: none;
    width: 30vw;
    height: 100%;
    border: 2px solid gray;
    border-radius: 20px;
}

.custom-modal.open {
    display: block;
}

.custom-modal.minimized {
    width: 20vw;
    height: 8vh;
    cursor: pointer;
    border-radius: 10px;
    border-bottom: 0;
}

.toggle-btn {
    cursor: pointer;
    margin-top: 0.5vh;
    margin-right: 1vw;
}

.modal-content {
    padding: 5px;
    border-radius: 8px;
}

.progress-bar {
    width: 100%;
    height: 5vh;
    padding: 5px;
    background-color: #007bff;
    border-radius: 10px;
    overflow-x: hidden;
    text-align: center;
    color: white;
}

.modal-content-header {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    color: rgb(60, 60, 60)
}

.upload-btn {
    font-size: 20px;
}

.file-name {
    font-size: 15px;
    margin-top: 5px;
    margin-left: 5px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgb(180, 179, 179);
}

.modal-heading {
    font-size: 18px;
    font-weight: 600;
    margin: 5px;
}

.failed-message {
    color: red;
    font-weight: 600;
    margin-top: -4px;
    margin-left: 4px;
}

.retry-btn {
    margin-left: 10px;
    padding: 5px 20px;
    border-radius: 20px;
    border: none;
    background-color: rgb(187, 210, 247);
    font-weight: 600;
}

.upload-success {
    color: green;
    font-weight: 600;
    margin-top: -4px;
    margin-left: 4px;
}

.success-icon {
    color: green;
    font-size: 20px;
}

.retry-icon {
    color: red;
    font-size: 20px;
}

.progress-result {
    margin-bottom: 5px;
}
.paginationBttns {
    width: 80%;
    height: 20px;
    list-style: none;
    display: flex;
    justify-content: center;
    color:rgb(91, 90, 90);
    font-weight: 700;
  }
  
  .paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid #bfbfc3;
    color: rgb(91, 90, 90);
    text-decoration: none;
    font-size: large;
    cursor: pointer;
  }
  
  .paginationBttns a:hover {
    background-color: #b8b9fc;
  }
  
  .paginationActive a {
    background-color: #b8b9fc;
  }
  
  .paginationDisabled a {
    display:none;
  }
.table{
    width:"100%";
}

.p-treetable-header{
    background-color: #0e3f6a;
    color:white
}

.p-treetable-thead{
    border-bottom: 2px solid rgb(202, 189, 189);
    text-align: left;
}

.p-treetable-tbody{
    border-bottom: 1px solid rgb(202, 189, 189);
}

tr{
    border-bottom: 1px solid rgb(202, 189, 189);
}
  
.p-breadcrumb{
    margin: 0;
    padding: 0;
    background-color: transparent;
    margin-bottom: 4vh;
    font-size: 18px;
    text-decoration: none;
}

.p-orderlist .p-component {
    width: inherit;
    background-color: transparent;
}

.p-orderlist-list-container {
    margin-left: 2px;
    width: 45vw;
    flex: none;
    font-size: 15px;
}

.p-orderlist-item {
    background-color: rgba(232, 232, 232, 0.982);
}

.p-highlight {
    background-color: rgba(181, 181, 181, 0.982);
    color: rgb(41, 40, 40)
}

.p-breadcrumb{
    margin: 0;
    padding: 0;
    background-color: transparent;
    margin-bottom: 4vh;
    font-size: 18px;
    text-decoration: none;
}

table,tr,td{
    border: none;
}

.course-detail-label{
    width: 20vw;
    font-weight: bold;
    padding-bottom: 10px;
}

.course-detail-value{
    margin-left: 50px;
    padding-bottom: 10px;
}



table,tr,td{
    border: none;
}

.video-detail-label{
    width: 20vw;
    font-weight: bold;
    padding-bottom: 10px;
}

.video-detail-value{
    margin-left: 50px;
    padding-bottom: 10px;
}

.p-breadcrumb{
    margin: 0;
    padding: 0;
    background-color: transparent;
    margin-bottom: 4vh;
    font-size: 18px;
    text-decoration: none;
}
